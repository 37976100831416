import React, { useEffect } from "react";
import { useState } from "react";
import { QueryClientProvider, QueryClient } from "react-query";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PageLoading from "./components/layouts/loading";
import { AppContext } from "./contexts/app-context";
import "./styles/tailwind.output.css";
import { AppGlobalData } from "./types/AppGlobalData";

// components import
const AgencyClientsPage = React.lazy(
  () => import("./pages/protected/agency/clients/ClientsPage")
);
const AgencyDashboardPage = React.lazy(
  () => import("./pages/protected/agency/DashboardPage")
);
const AgencyEmployeesPage = React.lazy(
  () => import("./pages/protected/agency/employees/EmployeesPage")
);
const AccessDeniedPage = React.lazy(
  () => import("./pages/public/AccessDeniedPage")
);
const AgencySignupPage = React.lazy(
  () => import("./pages/public/AgencySignupPage")
);
const LandingPage = React.lazy(() => import("./pages/public/LandingPage"));
const LoginPage = React.lazy(
  () => import("./pages/public/account/login/LoginPage")
);
const RegisterPage = React.lazy(
  () => import("./pages/public/account/register/RegisterPage")
);
const ActivateAccountPage = React.lazy(
  () => import("./pages/public/account/activate/ActivateAccountPage")
);
const EmployeeOnboardingPage = React.lazy(
  () => import("./pages/public/EmployeeOnboardingPage")
);
const EmployeeDashboardPage = React.lazy(
  () => import("./pages/protected/employee/DashboardPage")
);
const AgencyBroadcastsPage = React.lazy(
  () => import("./pages/protected/agency/BroadcastPage")
);
const ServiceUsersPage = React.lazy(
  () => import("./pages/protected/agency/serviceusers/ServiceUsersPage")
);
const CarePlansPage = React.lazy(
  () => import("./pages/protected/agency/clients/CarePlansPage")
);
const RiskAssessmentsPage = React.lazy(
  () => import("./pages/protected/agency/clients/RiskAssessmentsPage")
);

const ShiftsPage = React.lazy(
  () => import("./pages/protected/agency/shifts/ShiftsPage")
);
const ServiceUserPage = React.lazy(
  () => import("./pages/protected/agency/clients/ServiceUsersPage")
);
const DocumentTemplatePage = React.lazy(
  () => import("./pages/protected/agency/DocumentTemplatePage")
);
const PdfDocumentPreviewPage = React.lazy(
  () => import("./pages/protected/agency/PdfDocumentPreviewPage")
);
const UpdateDocumentTemplatePage = React.lazy(
  () => import("./pages/protected/agency/UpdateDocumentTemplatePage")
);

const ServiceUserAddressesPage = React.lazy(
  () => import("./pages/protected/agency/serviceusers/ServiceUserAddressesPage")
);

const ServiceUserOtherDetailsPage = React.lazy(
  () =>
    import("./pages/protected/agency/serviceusers/ServiceUserOtherDetailsPage")
);

const ServiceUserRelativesPage = React.lazy(
  () => import("./pages/protected/agency/serviceusers/ServiceUserRelativesPage")
);

const ServiceUserMedicinesPage = React.lazy(
  () => import("./pages/protected/agency/serviceusers/ServiceUserMedicinesPage")
);

const UserClientMappingPage = React.lazy(
  () => import("./pages/protected/admin/users/UserClientMappingPage")
);

const AgencyReportsPage = React.lazy(
  () => import("./pages/protected/agency/reports/Index")
);

const AgencyHandoverReportPage = React.lazy(
  () => import("./pages/protected/agency/reports/HandoverReportPage")
);

const TermsPage = React.lazy(() => import("./pages/public/terms/TermsPage"));
const TestPage = React.lazy(() => import("./pages/public/TestPage"));
const UserListPage = React.lazy(
  () => import("./pages/protected/admin/users/UserListPage")
);
const CustomerListPage = React.lazy(
  () => import("./pages/protected/agency/customers/CustomerListPage")
);

const queryClient = new QueryClient();

function App() {
  const [appData, setGlobalData] = useState<AppGlobalData>(() => {
    const savedGlobalData = sessionStorage.getItem("appData");
    return savedGlobalData ? JSON.parse(savedGlobalData) : {};
  });
  const appDataValue = { appData, setGlobalData };

  useEffect(() => {
    if (appData) {
      sessionStorage.setItem("appData", JSON.stringify(appData));
    } else {
      sessionStorage.removeItem("appData");
    }
  }, [appData]);

  return (
    <QueryClientProvider client={queryClient}>
      <AppContext.Provider value={appDataValue}>
        <BrowserRouter>
          <Routes>
            <Route
              path="/"
              element={
                <React.Suspense fallback={<PageLoading />}>
                  <LoginPage />
                </React.Suspense>
              }
            />
            <Route
              path="/agency-signup"
              element={
                <React.Suspense fallback={<PageLoading />}>
                  <AgencySignupPage />
                </React.Suspense>
              }
            />
            <Route
              path="/agency/reports"
              element={
                <React.Suspense fallback={<PageLoading />}>
                  <AgencyReportsPage />
                </React.Suspense>
              }
            />
            <Route
              path="/agency/reports/hanover/:clientId/client"
              element={
                <React.Suspense fallback={<PageLoading />}>
                  <AgencyHandoverReportPage />
                </React.Suspense>
              }
            />
            <Route
              path="/login"
              element={
                <React.Suspense fallback={<PageLoading />}>
                  <LoginPage />
                </React.Suspense>
              }
            />
            <Route
              path="/terms"
              element={
                <React.Suspense fallback={<PageLoading />}>
                  <TermsPage />
                </React.Suspense>
              }
            />
            <Route
              path="/register/:businessId/:email"
              element={
                <React.Suspense fallback={<PageLoading />}>
                  <RegisterPage />
                </React.Suspense>
              }
            />
            <Route
              path="/activate/:email"
              element={
                <React.Suspense fallback={<PageLoading />}>
                  <ActivateAccountPage />
                </React.Suspense>
              }
            />
            <Route
              path="/admin/user-client-mapping"
              element={
                <React.Suspense fallback={<PageLoading />}>
                  <UserClientMappingPage />
                </React.Suspense>
              }
            />

            <Route
              path="/test"
              element={
                <React.Suspense fallback={<PageLoading />}>
                  <TestPage />
                </React.Suspense>
              }
            />

            {/* {userValue.user.userId && (
              <Route path="/agency/dashboard" element={<AgencyDashboard />} />
            )} */}
            <Route
              path="/agency/dashboard"
              element={
                <React.Suspense fallback={<PageLoading />}>
                  <AgencyDashboardPage />
                </React.Suspense>
              }
            />
            <Route
              path="/employee/dashboard"
              element={
                <React.Suspense fallback={<PageLoading />}>
                  <EmployeeDashboardPage />
                </React.Suspense>
              }
            />
            <Route
              path="/agency/clients"
              element={
                <React.Suspense fallback={<PageLoading />}>
                  <AgencyClientsPage key="1233" />
                </React.Suspense>
              }
            />
            <Route
              path="/agency/document-template/agency/:agencyId/client/:clientId"
              element={
                <React.Suspense fallback={<PageLoading />}>
                  <DocumentTemplatePage />
                </React.Suspense>
              }
            />
            <Route
              path="/agency/document-template-edit/agency/:agencyId/client/:clientId"
              element={
                <React.Suspense fallback={<PageLoading />}>
                  <UpdateDocumentTemplatePage />
                </React.Suspense>
              }
            />
            <Route
              path="/agency/pdf-document-preview/agency/:agencyId/client/:clientId"
              element={
                <React.Suspense fallback={<PageLoading />}>
                  <PdfDocumentPreviewPage />
                </React.Suspense>
              }
            />
            <Route
              path="/agency/:agencyId/client/:clientId/service-users"
              element={
                <React.Suspense fallback={<PageLoading />}>
                  <ServiceUserPage />
                </React.Suspense>
              }
            />
            <Route
              path="/agency/employees"
              element={
                <React.Suspense fallback={<PageLoading />}>
                  <AgencyEmployeesPage />
                </React.Suspense>
              }
            />
            <Route
              path="/agency/broadcasts"
              element={
                <React.Suspense fallback={<PageLoading />}>
                  <AgencyBroadcastsPage />
                </React.Suspense>
              }
            />
            {/* <Route
              path="/agency/employee/:employeeId/addresses"
              element={
                <React.Suspense fallback={<PageLoading />}>
                  <EmployeeAddressPage />
                </React.Suspense>
              }
            />
            <Route
              path="/agency/employee/:employeeId/documents"
              element={
                <React.Suspense fallback={<PageLoading />}>
                  <EmployeeDocumentsPage />
                </React.Suspense>
              }
            /> */}

            <Route
              path="/agency/:agencyId/client/:clientId/care-plans"
              element={
                <React.Suspense fallback={<PageLoading />}>
                  <CarePlansPage />
                </React.Suspense>
              }
            />

            <Route
              path="/agency/:agencyId/client/:clientId/risk-assessments"
              element={
                <React.Suspense fallback={<PageLoading />}>
                  <RiskAssessmentsPage />
                </React.Suspense>
              }
            />
            <Route
              path="/agency/shifts"
              element={
                <React.Suspense fallback={<PageLoading />}>
                  <ShiftsPage />
                </React.Suspense>
              }
            />

            <Route
              path="/agency/service-users/:clientId/client"
              element={
                <React.Suspense fallback={<PageLoading />}>
                  <ServiceUsersPage />
                </React.Suspense>
              }
            />

            <Route
              path="/service-user/:serviceUserId/addresses"
              element={
                <React.Suspense fallback={<PageLoading />}>
                  <ServiceUserAddressesPage />
                </React.Suspense>
              }
            />

            <Route
              path="/service-user/:serviceUserId/other-details"
              element={
                <React.Suspense fallback={<PageLoading />}>
                  <ServiceUserOtherDetailsPage />
                </React.Suspense>
              }
            />

            <Route
              path="/service-user/:serviceUserId/relatives"
              element={
                <React.Suspense fallback={<PageLoading />}>
                  <ServiceUserRelativesPage />
                </React.Suspense>
              }
            />

            <Route
              path="/service-user/:serviceUserId/medications"
              element={
                <React.Suspense fallback={<PageLoading />}>
                  <ServiceUserMedicinesPage />
                </React.Suspense>
              }
            />

            <Route
              path="/agency/users"
              element={
                <React.Suspense fallback={<PageLoading />}>
                  <UserListPage />
                </React.Suspense>
              }
            />

            <Route
              path="/agency/customers"
              element={
                <React.Suspense fallback={<PageLoading />}>
                  <CustomerListPage />
                </React.Suspense>
              }
            />
            <Route
              path="/access-denied"
              element={
                <React.Suspense fallback={<PageLoading />}>
                  <AccessDeniedPage />
                </React.Suspense>
              }
            />
          </Routes>
        </BrowserRouter>
      </AppContext.Provider>
    </QueryClientProvider>
  );
}

export default App;
